<template>
  <div class="m-t-10">
    <div class="border-all bg-search p-tb-10 p-lr-10">
      <slot name="title"></slot>
    </div>
    <div class="bg-white  p-tb-10 p-lr-10">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>

<style></style>
