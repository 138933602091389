<template>
  <div class="font-size-20">
    <div class="flex-column flex-center">
      <div class="font-size-22">
        <slot name="text"></slot>
      </div>
      <div v-if="toCancelSecond">
        请您在
        <slot name="countdown"></slot>
        内完成支付，否则订单会被自动取消
      </div>
      <div class="m-t-10">
        <slot name="btn"></slot>
      </div>
    </div>
    <div v-show="isScroll" class="flex flex-between flex-row-center pay-count-down-two">
      <div>
        <span class="m-r-20 font-size-22">
          <slot name="text"></slot>
        </span>
        <span v-if="toCancelSecond">
          请您在
          <slot name="countdown"></slot>
          内完成支付，否则订单会被自动取消
        </span>
      </div>
      <div class="btn-box">
        <slot name="btn"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayCountdown",
  props: {
    timeValue: {
      type: Number
    },
    isScroll: {
      type: Boolean,
      default: false
    },
    toCancelSecond: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="less" scoped>
.pay-count-down-two {
  top: 40px;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 10px;
  position: absolute;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
.btn-box {
  margin-right: 30px;
}
</style>
