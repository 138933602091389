<template>
  <div class="client-page flex-column relative">
    <div class="p-tb-10 p-l-10 border-bottom-1 flex box-sizing h-40">
      <span class="cursor-pointer" @click="toBack">
        <i class="el-icon-arrow-left"></i>
        返回
      </span>
      <div class="flex1 text-center font-size-16 font-weight-bold">订单支付</div>
    </div>
    <div class="flex1 bg-table-header p-a-10  flex-column overflow-auto" ref="scrollContent" @scroll="scrollContent">
      <div class="bg-table-header p-tb-10">
        <PayCountdown :isScroll="isScroll" v-if="!isPaySuccess" :toCancelSecond="!!dataObj.toCancelSecond">
          <template #text>
            <span>
              应付金额：
              <span class="text-danger font-weight-bold">¥{{ Number(dataObj.totalAmount).toFixed(2) }}</span>
            </span>
          </template>
          <template #countdown>
            <Countdown
              v-if="!!dataObj.toCancelSecond"
              :timeValue="dataObj.toCancelSecond * 1000"
              @refresh="refreshFn"
            />
          </template>
          <template #btn>
            <el-button type="warning" round @click="toPayFn">去支付</el-button>
          </template>
        </PayCountdown>
        <!-- 支付成功 -->
        <!-- <div class="flex-column flex-center font-size-20" v-else>
          <div class="text-success font-weight-bold flex flex-row-center">
            <i class="el-icon-success font-size-22 m-r-4"></i>
            支付成功!
          </div>
          <div class="flex m-t-10 width-full">
            <div class="flex1 text-right  m-r-40">
              <span>实付金额：</span>
              <span class="text-danger">¥2000.00</span>
            </div>
            <div class="flex1">订单编号：6342423948923</div>
          </div>
          <div class="flex m-t-10">
            <el-button class="m-r-40" type="primary" size="small">前往商品浏览</el-button>
            <el-button type="primary" size="small">前往订单列表</el-button>
          </div>
        </div> -->
      </div>
      <Card v-for="(item, index) in dataObj.rows" :key="index">
        <template #title>
          <div class="flex flex-between flex-row-center">
            <div>订单号：{{ item.orderCode }}</div>
            <el-button type="text" size="mini" class="font-size-14" @click="toOrderDetail(item)">订单详情</el-button>
          </div>
        </template>
        <template #content>
          <OrderDetailItem :table="item.details" :isShow="false">
            <template #footer>
              <div>
                <span>共{{ item.details.length }}种，{{ item.totalQty }}件商品</span>
                <span class="m-l-20">合计：¥{{ Number(item.totalAmount).toFixed(2) }}</span>
              </div>
            </template>
          </OrderDetailItem>
        </template>
      </Card>

      <!-- 选择支付方式 -->
      <SelectPayMethod
        id="selectPay"
        class="m-t-10 select_pay"
        :query="queryParams"
        :totalAmount="dataObj.totalAmount"
        v-if="!isPaySuccess"
        @payChange="payChangeFn"
      />
      <div class="m-t-10 bg-white p-tb-20 p-lr-20 flex flex-col-end" v-show="isPaySuccess">
        <div style="width:300px">
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">商品总价</span>
            <span class="flex1 text-right">¥3000.00</span>
          </div>
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">优惠券</span>
            <span class="flex1 text-right">-¥50.00</span>
          </div>
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">积分抵扣</span>
            <span class="flex1 text-right">本次使用900积分抵扣9元</span>
          </div>
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">积分赠送</span>
            <span class="flex1 text-right">订单完成后积分 +103</span>
          </div>
          <div class="flex flex-row-center m-b-10">
            <span class="inline-block w-70 text-right m-r-20">应付金额</span>
            <span class="flex1 text-right">¥2000.00</span>
          </div>
          <div class="flex flex-row-center">
            <span class="inline-block w-70 text-right m-r-20">实付金额</span>
            <span class="flex1 text-right font-size-20 font-weight-bold text-danger">¥3000.00</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white p-tb-10 p-lr-10 text-right border-top-1" v-if="!isPaySuccess && payType !== 'lkl-counter'">
      <el-button type="warning" round @click="surePayOrder">
        确定(F4)
      </el-button>
    </div>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import OrderDetailItem from "../components/OrderDetailItem.vue";
import SelectPayMethod from "./components/SelectPayMethod.vue";
import PayCountdown from "../components/PayCountdown.vue";
import Countdown from "../components/Countdown.vue";

import { getOrderToPayList, postOrdersPay } from "@/api/order";

export default {
  name: "orderPay",
  components: {
    Card,
    OrderDetailItem,
    SelectPayMethod,
    PayCountdown,
    Countdown
  },
  data() {
    return {
      keyName: "f4",
      keyScope: "OrderPay",
      isScroll: false,
      isPaySuccess: false, //是否支付成功
      payType: null, //线上支付 lkl-counter 线下支付 financial
      dataObj: {
        toCancelSecond: 0, // 倒计时时间
        // 订单列表
        rows: []
      },
      payObj: {}, //付款信息
      queryParams: {}
    };
  },
  mounted() {
    const query = this.$route.query;
    if (query && query.data) {
      this.queryParams = JSON.parse(decodeURIComponent(query.data));
      this.getOrderDetailFn();
    }

    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    handleKeyCode(e) {
      switch (e.code) {
        case "F4":
          e.preventDefault();
          e.stopPropagation();
          if (!this.isPaySuccess && this.payType !== "lkl-counter") {
            this.surePayOrder();
          }
          break;
      }
    },
    refreshFn() {
      this.getOrderDetailFn();
    },
    //获取订单详情
    getOrderDetailFn() {
      getOrderToPayList(this.queryParams)
        .then(res => {
          this.dataObj = res || {};
        })
        .catch(err => {
          this.$message.error(err?.message);
          this.toBack();
        });
    },
    scrollContent(e) {
      if (e.target.scrollTop > 130) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
    payChangeFn(val) {
      this.payType = val.type;
      this.payObj = val;
    },
    toPayFn() {
      this.$refs.scrollContent.scrollTop = 2000;
      // console.log(this.$refs.scrollContent.scrollTop, "scrollContent");
      // this.goAnchor("selectPay");
    },
    //锚点跳转
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.getElementById(selector).scrollIntoView({
        behavior: "smooth"
      });
    },
    //返回
    toBack() {
      this.$router.go(-1);
    },
    //确定支付
    surePayOrder() {
      const params = {
        query: {
          ...this.queryParams
        },
        ...this.payObj
      };
      if (!params.voucherImages.length) {
        this.$message.warning("请上传支付凭证！");
        return;
      }
      postOrdersPay(params)
        .then(() => {
          this.$message.success("支付确认成功!");
          this.$router.replace({
            path: "/order/order-list",
            query: {
              type: "refresh"
            }
          });
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    //订单详情
    toOrderDetail(item) {
      const param = {
        path: "/order/order-list/order-detail",
        query: {
          id: item.id
        }
      };
      this.$router.push(param);
    }
  }
};
</script>

<style lang="less" scoped>
.scrollContent {
  min-height: 500px;
}
.select_pay {
  padding-bottom: 100px;
}
</style>
