<template>
  <div class="selectPayMethod flex">
    <Card class="flex1 bg-white" style="margin-top:0">
      <template #title>
        <div class="flex flex-between flex-row-center">
          <div>选择支付方式</div>
          <div class="font-size-22 font-weight-bold">
            应付金额：
            <span class="text-danger">¥{{ Number(totalAmount).toFixed(2) }}</span>
          </div>
        </div>
      </template>
      <template #content>
        <div class="flex-column p-tb-10 p-lr-10">
          <!-- 多选支付方式 -->
          <!-- <div class="flex">
            <el-checkbox v-model="dataObj.vipCard" style="height:40px;line-height:40px"> </el-checkbox>
            <div class="flex1 p-b-20 border-bottom-1 m-l-20">
              <div class="flex flex-row-center">
                <img src="" alt="icon" class="img-icon m-r-20" />
                <div class="flex1">
                  <div>
                    <span class="font-size-18">储值卡余额</span>
                    <span>（余额：¥{{ activeVipCard.balance }}）</span>
                  </div>
                </div>
                <div class="font-size-18 font-weight-bold">
                  <span>支付：</span>
                  <span class="text-danger">¥100.00</span>
                </div>
              </div>
              <div class="flex m-t-20 flex-wrap">
                <div
                  class="vip-card flex flex-center text-center relative"
                  v-for="(item, index) in vipCardList"
                  :key="index"
                  :class="[activeVipCard.id == item.id ? 'active' : '']"
                  @click="selectVipCard(item)"
                >
                  <div>
                    <div>{{ item.name }}</div>
                    <div class="font-size-12 text-666 m-t-10">{{ item.cardNo }}</div>
                  </div>
                  <div class="checked" v-if="activeVipCard.id == item.id">
                    <i class="el-icon-check text-danger"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex m-t-20">
            <el-checkbox v-model="dataObj.prepayment" style="height:40px;line-height:40px"> </el-checkbox>
            <div class="flex1 p-b-20 border-bottom-1 m-l-20">
              <div class="flex flex-row-center">
                <img src="" alt="icon" class="img-icon m-r-20" />
                <div class="flex1">
                  <span class="font-size-18">预付余额</span>
                  <span>（余额：¥100.00）</span>
                </div>
                <div class="font-size-18 font-weight-bold">
                  <span>支付：</span>
                  <span class="text-danger">¥100.00</span>
                </div>
              </div>
            </div>
          </div> -->
          <!-- 单选支付方式 -->
          <div class="flex m-t-20" v-for="(item, index) in payList" :key="index">
            <el-radio v-model="dataObj.type" :label="item.code" @change="payTypeChange"></el-radio>
            <div class="flex1 p-b-20  m-l-20" :class="[index != payList.length - 1 ? 'border-bottom-1' : '']">
              <div class="flex flex-row-center">
                <img :src="renderRadioIcon(item)" alt="icon" class="img-icon m-r-20" />
                <div class="flex1">
                  <span class="font-size-18">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
    <div class="code flex flex-center text-center">
      <!-- 线上支付 -->
      <div class="flex-column font-weight-bold flex-center p-tb-20" v-if="dataObj.type == 'lkl-counter'">
        <div class="">
          <span class="font-size-18">待支付：</span>
          <span class="text-danger font-size-22">¥{{ Number(dataObj.amount).toFixed(2) }}</span>
        </div>
        <div class="font-size-12 m-t-10">请使用支付宝或微信扫码进行支付</div>
        <div class="m-t-8">
          <div id="qrcode" ref="qrcode"></div>
        </div>
        <div class="m-t-10">
          <el-button plain round size="small" :disabled="!payCode" @click="QRcodeDownload('待支付二维码')">
            下载图片
          </el-button>
        </div>
        <div class="font-size-12 m-t-20">
          * 注意: 转账前请与商家核对身份及账单信息，转账时请谨慎操作.
        </div>
      </div>
      <!-- 线下支付 -->
      <div class="flex-column font-weight-bold flex-center p-tb-20" v-if="dataObj.type == 'financial'">
        <div class="">
          <span class="font-size-18">待支付：</span>
          <span class="text-danger font-size-22">¥{{ Number(dataObj.amount).toFixed(2) }}</span>
        </div>

        <div class="flex flex-between">
          <template v-for="(item, index) in companies.payments">
            <div :key="index" class="img_pay" v-if="item.url">
              <div class="font-size-12 m-t-10">{{ item.name }}</div>
              <div class="w-150 h-150 m-t-8" :ref="'imgUrl' + index">
                <img class="width-full height-full" :src="item.url" />
              </div>
              <div class="m-t-10">
                <el-button plain round size="mini" @click="QRcodeDownload1('imgUrl' + index, item.name)"
                  >下载图片</el-button
                >
                <!-- <el-button plain round size="mini" @click="downloadImg(item.url, item.name)">下载图片2</el-button> -->
              </div>
            </div>
          </template>
        </div>

        <div class="font-size-12 m-t-20">
          * 注意: 转账前请与商家核对身份及账单信息，转账时请谨慎操作.
        </div>
        <div class="width-full m-t-10 text-left">
          <div class="must">支付凭证上传</div>
          <div class="flex m-t-8">
            <UploadFileImg
              :maxSize="2"
              listType="picture-card"
              :limit="3"
              :fileList="voucherImagesList"
              :isMultiple="false"
              :showFileList="true"
              :hideFlag="true"
              :tip="`（${voucherImagesList.length}/3）图片大小2M以内`"
              @getFileList="fileList => getImgFileList(fileList)"
            >
            </UploadFileImg>
            <!-- <div class="upload_remark">图片大小2M以内</div> -->
          </div>
        </div>
        <div class="width-full m-t-10 text-left">
          <div>备注</div>
          <div class="flex m-t-8">
            <el-input
              type="textarea"
              placeholder="请输入备注内容"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="dataObj.remarks"
              maxlength="200"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../../components/Card.vue";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";

import UploadFileImg from "@/components/Upload/UploadFileImg.vue";

import { getOrdersPayList, getCompanies, getOrdersPayAmount, postOrdersPay } from "@/api/order";

export default {
  name: "SelectPayMethod",
  components: {
    Card,
    UploadFileImg
  },
  props: {
    query: {
      type: Object,
      default: () => {}
    },
    totalAmount: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      dataObj: {
        // vipCard: false, //储值卡余额
        // prepayment: false, //预付余额
        type: "", //线上支付 lkl-counter 线下支付 financial
        amount: "", //待支付金额
        voucherImages: [], //支付凭证上传
        remarks: "" //备注
      },
      voucherImagesList: [], //支付凭证上传
      activeVipCard: {},
      vipCardList: [
        {
          id: 1,
          name: "黄金会员卡",
          cardNo: "VIP202310310955276655",
          balance: "1000"
        },
        {
          id: 2,
          name: "白银会员卡",
          cardNo: "VIP202310310955276655",
          balance: "800"
        },
        {
          id: 3,
          name: "青铜会员卡",
          cardNo: "VIP202310310955276655",
          balance: "700"
        }
      ],
      //支付列表
      payList: [
        // {
        //   name: "在线支付",
        //   code: "lkl-counter",
        //   info: null
        // },
        // {
        //   name: "线下支付",
        //   code: "financial",
        //   info: null
        // }
      ],
      offlineIcon: require("@/assets/img/payIcon/线下支付.png"),
      onlineIcon: require("@/assets/img/payIcon/线上支付.png"),
      VIPIcon: require("@/assets/img/payIcon/储值卡.png"),
      prepaymentIcon: require("@/assets/img/payIcon/预付余额.png"),

      companies: {
        //收款信息
        payments: []
      },
      amount: "", //应付金额
      payCode: "",
      qrcode: null
    };
  },
  watch: {
    dataObj: {
      handler(val) {
        this.$emit("payChange", { ...val, ids: this.idsArr });
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    idsArr() {
      if (this.query.ids) {
        const idsArr = this.query.ids.split(",");
        return idsArr.map(item => Number(item));
      } else {
        return [];
      }
    }
  },
  mounted() {
    this.getOrdersPayListFn();
    this.getCompaniesFn();
  },
  methods: {
    renderRadioIcon(item) {
      if (item.name == "线下支付") {
        return this.offlineIcon;
      } else if (item.name == "在线支付") {
        return this.onlineIcon;
      }
    },
    getOrdersPayListFn() {
      const params = {
        query: {
          ...this.query
        },
        ids: this.idsArr
      };

      getOrdersPayList(params)
        .then(res => {
          this.payList = res || [];
          this.dataObj.type = this.payList[0].code;
          this.getOrdersPayAmountFn(this.dataObj.type);
          if (this.dataObj.type == "lkl-counter") {
            this.payTypeChange(this.dataObj.type);
          }
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    // 将图片转换为Base64
    imageUrlToBase64(img, item) {
      // 一定要设置为let，不然图片不显示
      const image = new Image();
      // 解决跨域问题
      image.setAttribute("crossOrigin", "anonymous");
      // 使用js方式请求图片资源, 需要避免使用缓存，设置url后加上时间戳，或者http头设置Cache-Control为no-cache
      const imageUrl = img + "&time=" + new Date().getTime();
      image.src = imageUrl;
      // image.onload为异步加载
      image.onload = () => {
        this.getImage(image, item);
      };
    },
    getImage(image, item) {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      item.url = canvas.toDataURL("image/jpeg");
    },
    getCompaniesFn() {
      getCompanies()
        .then(res => {
          this.companies = res || {};
          for (const item of this.companies.payments) {
            if (item.url) {
              this.imageUrlToBase64(item.url, item);
            }
          }
          this.$forceUpdate();
          // console.log("payments", this.companies.payments);
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    getOrdersPayAmountFn(payType) {
      const params = {
        query: {
          ...this.query
        },
        ids: this.idsArr,
        type: payType
      };
      getOrdersPayAmount(params)
        .then(res => {
          this.dataObj.amount = res || "";
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    payTypeChange(val) {
      this.qrcode = null;
      if (val == "lkl-counter") {
        this.payCode = "";
        const params = {
          query: {
            ...this.query
          },
          ids: this.idsArr,
          type: val
        };
        postOrdersPay(params)
          .then(res => {
            this.payCode = decodeURIComponent(res.url) || "";
            this.$nextTick(() => {
              this.qrcodeScan(this.payCode);
            });
          })
          .catch(err => {
            this.$message.error(err?.message);
          });
      }
    },
    //生成二维码
    qrcodeScan(text) {
      const w = 150;
      this.$refs.qrcode.innerHTML = "";
      this.qrcode = new QRCode("qrcode", {
        width: w, // 设置宽度，单位像素
        height: w, // 设置高度，单位像素
        text, // 设置二维码内容或跳转地址
        colorDark: "#000000",
        colorLight: "#FFFFFF",
        correctLevel: QRCode.CorrectLevel.Q
      });
    },
    getImgFileList(fileList) {
      this.voucherImagesList = fileList;
      this.dataObj.voucherImages = fileList.length ? fileList.map(item => item.origin) : [];
    },
    selectVipCard(item) {
      this.activeVipCard = item;
    },
    // 下载图片
    downloadImg(imgsrc, fileName) {
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.src = imgsrc + "&time=" + new Date().getTime();
      image.onload = function() {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = fileName || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      return Promise.resolve();
    },
    //仅下载二维码
    QRcodeDownload(fileName) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        const dom = this.$refs.qrcode;
        this.downloadImgFn(dom, fileName);
      }, 200);
    },
    // 下载图片
    QRcodeDownload1(imgDom, fileName) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        const dom = this.$refs[imgDom][0];
        this.downloadImgFn(dom, fileName);
      }, 200);
    },
    downloadImgFn(el, fileName) {
      html2canvas(el, {
        allowTaint: true,
        backgroundColor: "white",
        width: el.offsetWidth,
        height: el.offsetHeight,
        useCORS: true, //支持图片跨域
        scale: 1 //设置放大的倍数
      }).then(canvas => {
        const image = new Image();
        image.src = canvas.toDataURL("image/png");

        const link = document.createElement("a");
        link.href = image.src; //下载链接
        link.setAttribute("download", `${fileName}.png`);
        link.style.display = "none"; //a标签隐藏
        document.body.appendChild(link);
        link.click();
      });
    }
  }
};
</script>

<style lang="less" scoped>
.selectPayMethod {
  .code {
    width: 34%;
    background: #fffbfb;
  }
  .img-icon {
    width: 40px;
    text-align: center;
    object-fit: contain;
  }
  /deep/.el-checkbox {
    height: 40px;
    line-height: 40px;
    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      &::after {
        height: 10px;
        left: 6px;
        width: 4px;
      }
    }
  }
  .vip-card {
    width: 166px;
    height: 86px;
    border: 1px solid #6589f8;
    border-radius: 4px;
    margin: 4px 20px 4px 0;
    cursor: pointer;
    &:hover {
      border: 1px solid #f56c6c;
      // width: 180px;
      // height: 90px;
    }
    &.active {
      border: 1px solid #f56c6c;
    }
    .checked {
      position: absolute;
      top: 6px;
      right: 10px;
    }
  }

  /deep/.el-radio {
    height: 40px;
    line-height: 40px;
    margin-right: 0;
    .el-radio__inner {
      width: 18px;
      height: 18px;
    }
    .el-radio__label {
      display: none;
    }
  }
  .must::before {
    content: "*";
    color: #f56c6c;
  }
  .upload_remark {
    font-size: 12px;
    color: #606266;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    line-height: 22px;
  }
  .img_pay + .img_pay {
    margin-left: 50px;
  }
  #qrcode {
    width: 150px;
    height: 150px;
    background: #ffffff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
