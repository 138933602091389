<template>
  <div class="flex-column bg-search">
    <div class="border-trl p-tb-10 p-lr-10 flex flex-between" v-if="isShow">
      <slot name="header"></slot>
      <slot name="btn"></slot>
    </div>
    <el-table
      ref="orderDetailTable"
      class="table-base flex1"
      size="small"
      :data="tableData"
      border
      stripe
      style="width: 100%"
      max-height="350"
    >
      <el-table-column fixed type="index" label="序号" align="center" width="55"> </el-table-column>

      <el-table-column
        v-for="(column, index) in tableColumns"
        :key="index"
        :label="column.label"
        :prop="column.prop"
        :min-width="column.width"
        :fixed="column.fixed"
        :sortable="column.sortable"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="column.prop === 'name'" class="text-primary cursor-pointer" @click="toProductsDetails(scope.row)">
            <span>{{ scope.row.name }}</span>
          </span>
          <span v-else-if="column.prop === 'price'">
            ￥{{ isNaN(Number(scope.row.price)) ? "议价" : Number(scope.row.price).toFixed(2) }}
          </span>
          <span v-else-if="column.prop === 'amount'">
            ￥{{
              isNaN(Number(scope.row.price)) ? "议价" : (Number(scope.row.qty) * Number(scope.row.price)).toFixed(2)
            }}
          </span>

          <span v-else-if="column.prop === 'qty'" class="flex flex-center">
            <el-input-number
              v-if="isEdit"
              size="mini"
              class="flex1"
              v-model="scope.row.qty"
              :min="1"
              :max="10"
            ></el-input-number>
            <span v-else>{{ scope.row.qty }}</span>
            <span class="m-l-2" v-if="scope.row.unitName">{{ scope.row.unitName }}</span>
          </span>
          <span v-else-if="isEdit && column.prop === 'controls'">
            <el-button type="text" class="text-danger-btn" @click="deleteFn(scope.$index)">移除</el-button>
          </span>
          <span v-else>
            <span>{{ scope.row[column.prop] }}</span>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="border-lrb  p-tb-10 p-lr-10 ">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
const defaultColumns = [
  {
    label: "商品编码",
    prop: "code",
    width: "150",
    sortable: "custom"
  },
  {
    label: "商品名称",
    prop: "name",
    width: "200",
    sortable: "custom"
  },
  {
    label: "品牌",
    prop: "brand",
    width: "100"
  },
  {
    label: "产地",
    prop: "productionPlace",
    width: "100"
  },
  {
    label: "单价",
    prop: "price",
    width: "100",
    sortable: "custom"
  },
  {
    label: "订货数",
    prop: "qty",
    width: "150",
    sortable: "custom"
  },
  {
    label: "小计",
    prop: "amount",
    width: "100",
    sortable: "custom"
  },
  {
    label: "仓库",
    prop: "warehouse",
    width: "100"
  },
  {
    label: "生产批号",
    prop: "productBatch",
    width: "100"
  },
  {
    label: "商品分类",
    prop: "category",
    width: "100px"
  },
  {
    label: "车型",
    prop: "vehModel",
    width: "100px"
  },
  {
    label: "商品性质",
    prop: "property",
    width: "100px"
  },
  {
    label: "规格",
    prop: "model",
    width: "100px"
  },
  {
    label: "生产码",
    prop: "productionCode",
    width: "100px"
  }
];
export default {
  name: "OrderDetailItem",
  props: {
    table: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isShow: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  computed: {
    tableColumns() {
      if (this.isEdit) {
        const controlObj = {
          label: "操作",
          prop: "controls",
          width: "80",
          fixed: "right"
        };
        return [...defaultColumns, controlObj];
      }
      return [...defaultColumns];
    }
  },
  watch: {
    table: {
      handler() {
        this.tableData = this.table;
      },
      immediate: true
    },
    tableData: {
      handler(val) {
        this.$emit("change", val);
      },
      deep: true
    }
  },
  methods: {
    deleteFn(index) {
      this.tableData.splice(index, 1);
    },
    // 跳转到商品详情页
    toProductsDetails(item) {
      // if (item.goodsId) {
      //   item.id = item.goodsId;
      // }
      // this.$router.push({
      //   path: "/products/details",
      //   query: {
      //     data: encodeURIComponent(JSON.stringify(item))
      //   }
      // });
      this.$router.push({
        path: "/products/details",
        query: {
          id: item.id,
          type: "order"
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.border-trl {
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}
.border-lrb {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
</style>
