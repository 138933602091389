<template>
  <span class="text-danger">{{ restTime }}</span>
</template>

<script>
export default {
  name: "Countdown", //倒计时
  props: {
    timeValue: {
      //时间戳
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      restTime: "", //剩余时间
      timeStamp: 0, //时间戳
      timer: null //定时器
    };
  },
  watch: {
    timeValue: {
      handler(val) {
        this.timeStamp = val;
      },
      immediate: true
    }
  },
  mounted() {
    // 倒计时,每一秒执行一次
    // this.timeStamp = this.timeValue;
    if (this.timer) clearInterval(this.timer);
    this.timer = setInterval(this.getRestTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 时间戳转时分秒
    toHHmmss(data) {
      let s;
      // const hours = Math.floor((data / 1000 / 60 / 60) % 24);
      const hours = Math.floor(data / 1000 / 60 / 60);
      const minutes = Math.floor((data / 1000 / 60) % 60);
      const seconds = Math.floor((data / 1000) % 60);

      s =
        (hours < 10 ? "0" + hours : hours) +
        "时" +
        (minutes < 10 ? "0" + minutes : minutes) +
        "分" +
        (seconds < 10 ? "0" + seconds : seconds) +
        "秒";
      if (s.length > 9) {
        s = s.slice(0, 9);
      }
      return s;
    },
    // 时分秒转时间戳
    toTimeStamp(date) {
      let s = "";
      const hour = date.split(":")[0];
      const min = date.split(":")[1];
      const sec = date.split(":")[2];
      s = Number(hour * 3600) + Number(min * 60) + Number(sec);
      s = s * 1000;
      return s;
    },
    // 初始化获取时间
    getRestTime() {
      // 赋值转时分秒
      this.restTime = this.toHHmmss(this.timeStamp);
      // 所以不是大于0秒
      if (this.timeStamp >= 1000) {
        // 1000为一秒
        // 如果大于1秒
        this.timeStamp = this.timeStamp - 1000;
      } else {
        // 倒计时最后一秒将剩余时间修改
        this.restTime = "00时00分00秒";
        // 清除定时器
        clearInterval(this.timer);
        //结束倒计数，刷新列表
        this.$emit("refresh");
        console.log("结束定时器!");
      }
    }
  }
};
</script>

<style></style>
